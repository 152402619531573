import React, { useState, useEffect } from 'react';
import { IconButton, Button, Badge, AppBar, Toolbar, Tooltip, Typography, Divider, Box, Modal} from '@mui/material';
import {  IconArrowNarrowRight,IconX } from '@tabler/icons-react';

const InvalidLicense = () => {
    const [open, setOpen] = useState(true);
    const handleClose = () => {
      setOpen(false);
      window.location.reload();
    };
    return ( 
        <>
        <Modal id="modal" open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box id="forgotPassword">
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: 'grey.500',
            }}
          >

            <IconX sx={{ fontSize: 32 }} />

          </IconButton>
      
            <>
              
             
            </>
        
            <>
              <img
                src="/ForgotPassword.svg"
                alt="Illustration"
                style={{ maxWidth: '240px', width: '100%', height: 'auto', marginBottom: '16px' }}
              />
              <Typography id="modal-modal-title" variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#0262AB' }}>
                License Exceeded
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4, color: '#727272', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.2rem' } }}>
              You have exceeded your license limit. Please logout from other browsers or devices to continue.
              </Typography>
             
            </>
         
        </Box>
      </Modal>
        </>
     );
}
 
export default InvalidLicense;
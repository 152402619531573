import React, { useState, useEffect, useRef } from 'react';
import '../styles/banner.scss';
import { Container } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import {useMediaQuery, AppBar, Button, Box, Collapse, Toolbar, Tooltip, IconButton, Menu, MenuItem, Avatar, ListItemIcon, Divider, Drawer, List, ListItem, ListItemAvatar, ListItemButton, ListSubheader, ListItemText } from '@mui/material';
import {IconX, IconLogout2, IconUser, IconInfoCircle, IconLockSquareRounded, IconSend, IconListCheck, IconUserSquareRounded, IconUserFilled, IconChevronDown, IconChevronUp, IconBuilding, IconBriefcase, IconMapPin, IconPhone, IconMail, IconLock, IconEye, IconEyeOff, IconLogin, IconCheck, IconArrowForward, IconArrowLeft, IconArrowRight} from '@tabler/icons-react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { logPageViews,UserSession } from '../Services/LoginService';
import InvalidLicense from '../components/InvalidLicense'
import { useTheme } from '@mui/material/styles';
import MetaTags from 'react-meta-tags';

const Banner = () => {

  const history = useHistory();
  const handleLogoutClick = () => {
//    localStorage.removeItem('token');
    logPage()
    async function logPage() {
      await logPageViews("Logout")
    }
    CheckUserSession()
        async function CheckUserSession() {
          if(localStorage.getItem("sessionId") !== null && localStorage.getItem("userId") !== null)
            {
                if((await UserSession('Logout',localStorage.getItem("sessionId"),'',localStorage.getItem("userId"),'','','','','','','')).data == false)
                    {
                      localStorage.clear();
                      history.push('/')
                      handleMenuClose();
                    }else{
                       
                    }
            }
          
        }
};
const [openInvalidModal, setOpenInvalidModal] = useState(false);
useEffect(() => {
   
  CheckUserSessionTime()
  async function CheckUserSessionTime() {
    if(localStorage.getItem("sessionId") !== null && localStorage.getItem("userId") !== null)
      {
          if((await UserSession('Check',localStorage.getItem("sessionId"),'',localStorage.getItem("userId"),'','','','','','','')).data == false)
              {
                setOpenInvalidModal(true);
                 localStorage.clear()
              }
      }
    
  }
}, []);

const handleLogoClick = () => {
  history.push('/Home'); // Always redirect to login
};


const [openDrawer, setDrawerOpen] = React.useState(false);
const toggleDrawer = (newOpen) => () => {
  setDrawerOpen(newOpen);
};
const handleDrawerClose = () => {
  setDrawerOpen(false);
};
const [anchorEl, setAnchorEl] = useState(null);
const open = Boolean(anchorEl);
const handleMenuClose = () => {
  setAnchorEl(null);
};


const theme = useTheme();
const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
const handleClick = (event) => {
  if (isLargeScreen) {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  } else {
    // This logic prevents unnecessary re-renders
    setDrawerOpen(prevState => !prevState);
  }
};
const [ipAddress, setIpAddress] = useState('');
useEffect(() => {
  // Fetch IP address from ipify API
  fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      setIpAddress(data.ip);
      localStorage.setItem('ip', data.ip); // Store IP address in localStorage
    })
    .catch(error => console.error('Error fetching IP address:', error));
}, []);

const fullName = localStorage.getItem('fullName') || 'Guest'; // Get full name of user
const email = localStorage.getItem('email') || 'Guest'; // Get full name of user
const firstLetter = fullName.charAt(0).toUpperCase();

return (<>
 {openInvalidModal && <div> <InvalidLicense /></div>}
  <MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
  </MetaTags>
    <AppBar position="fixed" id="navBanner">
    <Container fluid>
      <Toolbar>
        <Tooltip title={`Your IP address is ${ipAddress || localStorage.getItem('ip')}`}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleLogoClick}>
            <img src="/Logo.svg" id="logo-img" alt="Logo" />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }}></div>
        {!window.location.pathname.toLowerCase().includes('/feedback') 
                  ? 
                   <Button id="feedbackButton" variant="outlined" color="primary" onClick={()=>history.push('/feedback')}>
                  Send Feedback
                </Button>
                  : null
              }
                 
        {/* Large screen menu */}
            <IconButton
                edge="end"
                color="inherit"
                aria-label="user-menu"
                onClick={handleClick}
                id="avatarButton"
                
              >
            <Avatar alt="User">
                <IconUserFilled /> 
            </Avatar>
            </IconButton>
            
            <Menu id="bannerMenu" anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          <MenuItem className="menuItem"> 
              {fullName}
              <span>{email}</span>
            </MenuItem>
            <Divider sx={{ my: 4, borderBottomWidth: 1, borderColor: '#727272' }}/>
            <MenuItem className="menuItem"  onClick={()=>history.push('/MyAccount')}>
              <ListItemIcon>
                <IconUser />
              </ListItemIcon>
              Manage Account
            </MenuItem>
            <MenuItem className="menuItem" onClick={handleLogoutClick}>
              <ListItemIcon>
                <IconLogout2 />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu> 

        {/* Small screens menu drawer */}
        
          <Drawer id="bannerDrawerMobile" open={openDrawer} anchor={'right'}  sx={{ '& .MuiDrawer-paper': { width: '68vw',  }, '&.MuiDrawer-root': { zIndex: 1300}}}  ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}> 
           <Box p={2} sx={{ height: '100%', paddingBottom: 4}}> {/* Ensure the Box has a defined height */}
           <IconButton
               onClick={handleDrawerClose}
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                color: 'grey.500',
              }}
            >
           
          <IconX sx={{fontSize: 32}}/>
          
        </IconButton>
              <List sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <ListItem>
                  
                  <ListItemText 
                    primary={fullName} 
                    primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '24px', color: '#0262AB' } }} 
                    secondary={email}  
                    secondaryTypographyProps={{ style: { fontSize: '20px', color: '#727272' } }}
                  />
                </ListItem>
                <Divider sx={{ my: 4, borderBottomWidth: 1, borderColor: '#727272' }} />
                <List component="div"className='subList'
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    ACCOUNT
                  </ListSubheader>
                }
                sx={{mb: 4}}
              >
                  <ListItemButton onClick={()=>history.push('/MyAccount')}>
                    <ListItemIcon> <IconUser /> </ListItemIcon>
                    <ListItemText primary={'Manage Account'} />
                  </ListItemButton>
                </List>
                
                <List component="nav"className='subList'
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    GENERAL
                  </ListSubheader>
                }
              >
                <ListItem className="itemList" disablePadding>
                  <ListItemButton onClick={()=>history.push('/about')}>
                    <ListItemIcon> <IconInfoCircle /> </ListItemIcon>
                    <ListItemText primary="About" />
                  </ListItemButton>
                </ListItem>
                <ListItem className="itemList" disablePadding>
                  <ListItemButton onClick={()=>history.push('/privacy')}>
                    <ListItemIcon> <IconLockSquareRounded /> </ListItemIcon>
                    <ListItemText primary="Privacy" />
                  </ListItemButton>
                </ListItem>
                <ListItem className="itemList" disablePadding>
                  <ListItemButton onClick={()=>history.push('/feedback')}>
                    <ListItemIcon> <IconSend /> </ListItemIcon>
                    <ListItemText primary="Feedback" />
                  </ListItemButton>
                </ListItem>
                <ListItem className="itemList" disablePadding>
                  <ListItemButton onClick={()=>history.push('/terms')}>
                    <ListItemIcon> <IconListCheck /> </ListItemIcon>
                    <ListItemText primary="Terms & Conditions" />
                  </ListItemButton>
                </ListItem>
                </List>
                <div style={{ flexGrow: 1 }}></div>
                <Divider sx={{ my: 4, borderBottomWidth: 1, borderColor: '#727272' }}/>
                
                
                <ListItem className="itemList" disablePadding>
                  <ListItemButton className="itemButton" onClick={handleLogoutClick}>
                    <ListItemIcon>
                      <IconLogout2 />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItemButton>
                </ListItem>
                <ListItem className="itemList " >
                    <ListItemText primary="&copy; MIMS Australia. All rights reserved." />
                </ListItem>
              
              </List>
            </Box>
          </Drawer>
          
       
      </Toolbar>
    </Container>
  </AppBar>
 
  </>);
}

export default Banner;
import React, { useState, useEffect, useRef } from 'react';
import '../styles/LandingPage.scss';
import { Container, Col, Row, Card, Carousel} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { isLoggedIn } from '../Services/Auth';
import {CheckuserSub} from '../Services/LoginService';
import { Button, IconButton, AppBar, Toolbar, Tooltip,} from '@mui/material';


const LandingPageBanner = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setLoggedIn(isLoggedIn());
  }, []);

  const history = useHistory();
  const handleSignUpClick = async() => {
    if (loggedIn) {
     //console.log(await CheckuserSub())
     if((await CheckuserSub()).data == false)
      {
        localStorage.clear()
        //console.log('fals')
        window.location.reload();
      }else{
        history.push('/home');
        window.location.reload();
      }
      //history.push('/home'); // Redirect to homepage if logged in
    } else {
      history.push('/signup'); // Redirect to signup if not logged in
      window.location.reload();
    }
  };
  const handleSignInClick = () => {
    history.push('/login'); // Always redirect to login
    window.location.reload();
  };
  const handleLogoClick = () => {
    history.push('/'); // Always redirect to login
    //window.location.reload();
  };

  const [ipAddress, setIpAddress] = useState('');
  useEffect(() => {
    // Fetch IP address from ipify API
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        setIpAddress(data.ip);
        localStorage.setItem('ip', data.ip); // Store IP address in localStorage
      })
      .catch(error => console.error('Error fetching IP address:', error));
  }, []);
  return (
  <>
  <AppBar position="fixed" id="banner">
    <Container fluid>
      <Toolbar>
        <Tooltip title={`Your IP address is ${ipAddress || localStorage.getItem('ip')}`}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleLogoClick}>
            <img src="/Drugs4dent.svg" id="logo-img" alt="Logo" />
          </IconButton>
        </Tooltip>
        <div style={{ flexGrow: 1 }}></div>
        {loggedIn ? (
        <Button id="signupButton" variant="contained" color="primary" onClick={handleSignUpClick}>Go To Homepage</Button>
      ) : (
        <>
          <Button id="signupButton" variant="contained" color="primary" onClick={handleSignUpClick}>Get Started</Button>
          <Button id="signinButton" onClick={handleSignInClick}>Sign In</Button>
        </>
      )}
      </Toolbar>
    </Container>
  </AppBar>
  
  </>
  );
}

export default LandingPageBanner;